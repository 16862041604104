/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://jfmoe3clzzgnnfhijutmdag6za.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qbynieesanhzzh65ihbc76krgy",
    "aws_cognito_identity_pool_id": "us-east-1:7b66eda3-6dca-44fa-985d-a5cb2faeb01f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_p6WEpoGgN",
    "aws_user_pools_web_client_id": "2qk9jit9gtesqih2kcimtfh9l5",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_content_delivery_bucket": "timeplay-cinema-20210704180016-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://timeplay-cinema-20210704180016-hostingbucket-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
