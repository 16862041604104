import React, { useContext, useEffect, useState } from "react";
import { Navigate, Routes, Route, useRouteError } from "react-router-dom";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { LogTrackingContext } from "../context/LogTrackingContext";

import { ChannelContainer } from "../features/channel";


interface RouteError {
  statusText?: string;
  message?: string
}

const PageNotFound = () => {
  return (
    <div id="error-page">
      <h1>Page Not Found</h1>
    </div>
  );
}

const ErrorPage = () => {
  const error = useRouteError() as RouteError;
  console.error(error);

  return (
    <div id="error-page">
      <h1>{error.statusText || error.message}</h1>
    </div>
  );
}

const LoadingScreen = () => {
  const [isTakingLong, setIsTakingLong] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsTakingLong(true);
    }, 10000);
  }, []);

  return (
    <Segment
      style={{
        backgroundColor: "#0032b0",
      }}
    >
      <Dimmer active>
        <Loader>
          <div
            style={{
              lineHeight: 2,
            }}
          >
            Loading
            <br />
            {isTakingLong}
          </div>
        </Loader>
      </Dimmer>
    </Segment>
  );
};

export const AppRouter = () => {
  const { ready } = useContext(LogTrackingContext);

  return (
    <>
      {
        ready ? (
          <Routes>
            <Route path="/event/:channel_name" element={<ChannelContainer />} errorElement={<ErrorPage />}></Route>
            <Route path="/404" element={<PageNotFound />}></Route>
            <Route path="*" element={<Navigate to="/404" replace />}></Route>
          </Routes>
        ) : (
            <></>
        )
      }
    </>
  );
};
