import React, { FC, PropsWithChildren, createContext, useEffect, useState, useRef } from "react";
import { createLogger, RefreshableError, sleep, UnknownStateError } from "../../../common/utils";
import { getChannel, listEdgeNodes } from "../../../graphql/queries";
import { v4 as uuidv4 } from "uuid";
import { callGraphQL, exponentialSleep } from "../utils";
import { useErrorBoundary } from "react-error-boundary";

///////////////////////////////////////////////////////////////////////////////
// Definitions
///////////////////////////////////////////////////////////////////////////////
const MAX_CHANNEL_DATA_RETRIEVAL_ATTEMPTS = 5;

export enum ChannelState {
  UNINITIALIZED,
  INITIALIZING,
  DATA_RETRIEVED,
  READY,
  CHANNEL_EMPTY,
}

type EdgeNodeQueryResults = {
  listEdgeNodes: {
    items: EdgeNode[];
  }
}
type EdgeNode = {
  id: string;
  host: string;
  mountpointID: number;
  channelID: string;
  showID: string;
  channel: Channel;
  createdAt: string;
  updatedAt: string;
};

type ChannelQueryResult = {
  getChannel: Channel
}
type Channel = {
  name: string;
  players: number;
  capacity: number;
  createdAt: string;
  updatedAt: string;
  sessionId: string;
  edgeNodes: {
    items?: EdgeNode[];
    nextToken: any;
  };
};

type ChannelData = {
  isChannelEmpty: Boolean;
  edgeNodes?: EdgeNode[];
  sessionId?: string;
}

///////////////////////////////////////////////////////////////////////////////
// Helper functions
///////////////////////////////////////////////////////////////////////////////
async function retryableGetConnectionData(channelName: string, maxRetry = 6, retryDelay = 5000): Promise<ChannelData> {
  let retryCount = 0;

  let currentError;
  while (retryCount <= maxRetry) {
    try {
      let channelData = await getConnectionData(channelName);
      return channelData;
    } catch (e) {
      console.warn(`Failed to retrieve connection data, attempt ${retryCount}: ${e}`);
      currentError = e;
      retryCount = retryCount + 1;
      await sleep(retryDelay);
    }
  }

  throw currentError;
}
  
async function getConnectionData(channelName: string): Promise<ChannelData> {
  const edgeNodePromise = getEdgeNodeData(channelName);
  const sessionDataPromise = getSessionData(channelName);

  const [edgeNodeData, sessionData] = await Promise.all([edgeNodePromise, sessionDataPromise]);

  if (!edgeNodeData || !sessionData) {
    const failedComponents = [];
    if (!edgeNodeData) failedComponents.push("EdgeNodeData");
    if (!sessionData) failedComponents.push("SessionData");

    throw new RefreshableError("MEDIA_SERVER_UNREACHABLE", `failed to retrieve [${failedComponents.join(", ")}] after ${MAX_CHANNEL_DATA_RETRIEVAL_ATTEMPTS} attempts`);
  }

  const edgeNodes = edgeNodeData.listEdgeNodes.items;
  const sessionId = sessionData.getChannel?.sessionId;

  // if both edge and session is missing, then the gameserver is likely not playing
  if (edgeNodes.length === 0 && sessionId === undefined) {
    return {
      isChannelEmpty: true,
      edgeNodes: undefined,
      sessionId: undefined,
    };
  }

  // if there is no interaction with the room service for more than the
  // timeout(default 7200 seconds), then the session data may be removed
  // this is here to inform us of this case.
  if (edgeNodes.length === 0 || sessionId === undefined) {
    let missingData = "session";
    if (edgeNodes.length === 0) missingData = "edge"

    throw new RefreshableError("UNKNOWN_CHANNEL_STATE", `channel is in an unknown state, missing '${missingData}' data`, true, true, 60000);
  }

  return {
    isChannelEmpty: false,
    edgeNodes: edgeNodes,
    sessionId: sessionId,
  }
}

// TODO: refactor to incorporate the retry logic into callGraphQL
async function getEdgeNodeData(channelName: string): Promise<EdgeNodeQueryResults | undefined> {
  const logger = createLogger("[ChannelContext]");
  let edgeNodesResponse = undefined;

  for (let i = 0; i < MAX_CHANNEL_DATA_RETRIEVAL_ATTEMPTS; i++) {
    try {
      edgeNodesResponse = await callGraphQL<EdgeNodeQueryResults>(listEdgeNodes, { filter: { channelID: { eq: channelName } } });
    } catch (err) {
      logger.warn(`edgenode information retrieval failed with error: ${err}`);
    }
    if (edgeNodesResponse?.data) {
      logger.debug(`edgenode information: ${JSON.stringify(edgeNodesResponse.data)}`);
      break;
    }

    logger.warn(`node data retrieval retry ${i+1}`);
    await exponentialSleep(i);
  }

  return edgeNodesResponse?.data;
}

async function getSessionData(channelName: string): Promise<ChannelQueryResult | undefined> {
  const logger = createLogger("[ChannelContext]");
  let channelResponse = undefined;

  for (let i = 0; i < MAX_CHANNEL_DATA_RETRIEVAL_ATTEMPTS; i++) {
    try {
      channelResponse = await callGraphQL<ChannelQueryResult>(getChannel, { name: channelName });
    } catch (err) {
      logger.warn(`channel information data retrieval failed with error: ${err}`);
    }
    if (channelResponse?.data) {
      logger.debug(`channel information: ${JSON.stringify(channelResponse.data)}`);
      break;
    }

    logger.warn(`session data retrieval retry ${i+1}`);
    await exponentialSleep(i);
  }

  return channelResponse?.data;
}

///////////////////////////////////////////////////////////////////////////////
// Context
///////////////////////////////////////////////////////////////////////////////
type Props = {
  channelName: string;
} & PropsWithChildren;

type ChannelContextType = {
  channelName: string;
  channelState: ChannelState;
  gameSessionId: string | undefined;
  selectedNode: EdgeNode | undefined;
  startChannelDataFetch: () => void;
  restartChannelDataFetch: () => void;
  randomizeNode: () => void;
};

export const ChannelContext = createContext<ChannelContextType>({
  channelName: "",
  channelState: ChannelState.UNINITIALIZED,
  gameSessionId: undefined,
  selectedNode: undefined,
  startChannelDataFetch: () => { },
  restartChannelDataFetch: () => { },
  randomizeNode: () => { },
});

export const ChannelProvider: FC<Props> = ({ children, channelName }) => {
  const logger = createLogger("[ChannelContext]");
  const { showBoundary } = useErrorBoundary();

  // if startChannelDataFetch is called multiple times, then fetchIdRef is used
  // to set only the latest startChannelDataFetch call results, also used as a
  // "isMounted" check
  const fetchIdRef = useRef<string | undefined>(undefined);

  const [channelState, setChannelState] = useState<ChannelState>(ChannelState.UNINITIALIZED);
  const [gameSessionId, setGameSessionId] = useState<string | undefined>(undefined);
  const [edgeNodes, setEdgeNodes] = useState<EdgeNode[] | undefined>(undefined);
  const [selectedNode, setSelectedNode] = useState<EdgeNode | undefined>(undefined);

  const reset = (fetchId: string | undefined) => {
    if (fetchId === undefined) return;
    logger.log(`reseting state info for ${fetchId}`);
    if (fetchId !== fetchIdRef.current) {
      logger.warn(`attempting to reset with a startId that is not equal to current, skipping`);
      return;
    };

    setChannelState(ChannelState.UNINITIALIZED);
    setGameSessionId(undefined);
    setEdgeNodes(undefined);
    setSelectedNode(undefined);

    fetchIdRef.current = undefined;
  }

  // Exported functions
  ///////////////////////////////////////////////////////////////////////////////
  const startChannelDataFetch = () => {
    reset(fetchIdRef.current);

    const newFetchId = uuidv4();
    fetchIdRef.current = newFetchId;
    setChannelState(ChannelState.INITIALIZING);
    logger.info(`new channel data fetch started (ref=${newFetchId})`);
  }

  const restartChannelDataFetch = () => {
    startChannelDataFetch();
  }

  const randomizeNode = () => {
    if (channelState !== ChannelState.DATA_RETRIEVED && channelState !== ChannelState.READY) {
      showBoundary(new RefreshableError("INVALID_CHANNEL_DATA_STATE", `attempted to randomize node before node data retrieved`));
      return;
    }

    // This should never happen, as this should only be called after the data
    // is retrieved and verified.  An error should have already been thrown if
    // there are no edge nodes in getConnectionData
    if (!edgeNodes || edgeNodes.length === 0) {
      showBoundary(new UnknownStateError());
      return;
    }

    const nodeIndex = Math.round(Math.random() * (edgeNodes.length - 1));
    setSelectedNode(edgeNodes[nodeIndex]);
    setChannelState(ChannelState.READY);
  }

  // State management
  ///////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    return () => {
      logger.debug("unmounting!");
      reset(fetchIdRef.current);
    }
  }, []);

  useEffect(() => {
    if (channelState !== ChannelState.INITIALIZING) return;
    const closureFetchId = fetchIdRef.current;
    setChannelState(ChannelState.INITIALIZING);

    retryableGetConnectionData(channelName).then(({ isChannelEmpty, edgeNodes, sessionId }) => {
      if (isChannelEmpty) {
        setChannelState(ChannelState.CHANNEL_EMPTY);
        return;
      }

      if (closureFetchId !== fetchIdRef.current) return;
      setGameSessionId(sessionId);
      setEdgeNodes(edgeNodes);
      setChannelState(ChannelState.DATA_RETRIEVED);
    }).catch((err) => {
      if (closureFetchId !== fetchIdRef.current) return;
      showBoundary(err);
    });
  }, [channelName, channelState]);

  useEffect(() => {
    if (channelState !== ChannelState.DATA_RETRIEVED) return;
    randomizeNode();
  }, [channelState]);

  // JSX
  ///////////////////////////////////////////////////////////////////////////////
  return (
    <ChannelContext.Provider value={{
      // states
      channelName,
      channelState,
      gameSessionId,
      selectedNode,

      // functions
      startChannelDataFetch,
      restartChannelDataFetch,
      randomizeNode,
    }}>
      {children}
    </ChannelContext.Provider>
  );
};
