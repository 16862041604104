import React, { useContext, useEffect, useState } from "react";

import { createLogger, DEFAULT_REFRESH_DELAY, DisplayableError, RefreshableError, shouldRefreshResolver, shouldSendLogsResolver } from "../../../common/utils";
import { LogTrackingContext } from "../../../context/LogTrackingContext";
import { Dimmer, Header, Icon } from "semantic-ui-react";
import { useUUID } from "../../../hooks/useUUID";

export const ErrorDisplay: React.FC<{ error: Error }> = ({ error }) => {
  const logger = createLogger("[ChannelContainer]");
  const urlParams = new URLSearchParams(window.location.search);
  let clientId = useUUID();
  if (urlParams.get("opaque_id")) {
    clientId = urlParams.get("opaque_id");
  }

  const { submitLogs } = useContext(LogTrackingContext);
  const [errorTitle, setErrorTitle] = useState<string | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function processError() {
      if (error instanceof RefreshableError) {
        logger.log("Error occured:");
        logger.log(`${error.errorTitle} ${`(cause: ${error.cause})`}: ${error.message}`);
        logger.log(`${error.stack}`);

        setTimeout(async () => {
          if (error.shouldSendLogs) {
            await submitLogs(clientId, `Logs from - ${clientId} - ${window.location.pathname}`);
          }

          if (error.shouldRefresh) {
            setTimeout(() => {
              window.location.reload();
            }, error.refreshDelay);
          }
        }, 0);
      } else {
        logger.log("Unknown error occured:");
        logger.log(`${`(cause: ${error.cause})`}: ${error.message}`);
        logger.log(`${error.stack}`);

        setTimeout(async () => {
          if (shouldSendLogsResolver()) {
            await submitLogs(clientId, `Logs from - ${clientId} - ${window.location.pathname}`);
          }

          if (shouldRefreshResolver()) {
            setTimeout(() => {
              window.location.reload();
            }, DEFAULT_REFRESH_DELAY);
          }
        }, 0);
      }

      if (error instanceof DisplayableError) {
        setErrorTitle(error.errorTitle);
      }

      setErrorMsg(error.message);
    }
    processError();
  }, [clientId, error]);

  return (
    <Dimmer.Dimmable>
      <Dimmer active={true}>
        <Header as="h2" icon inverted>
          <Icon name="exclamation triangle" />
          {
            errorTitle ? (
              <>
                <div style={{ marginBottom: "20px" }}>{errorTitle}</div>
                <Header.Subheader>
                  <div
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    {errorMsg}
                  </div>
                </Header.Subheader>
              </>
            ) : (
              <>{error.name}: {error?.message}</>
            )}
        </Header>
      </Dimmer>
      <div style={{ height: "100vh" }} />
    </Dimmer.Dimmable>
  );
}