export type WebRTCMetric = {
  "@timestamp"?: any;
  audio?: {
    stream: any;
    track: any;
  };
  video?: {
    stream: any;
    track: any;
  };
  connectedIceCandidate?: {
    connection?: any;
    local?: any;
    remote?: any;
  };
  transport?: any;
};

export function createWebRTCMetric(stats: RTCStatsReport) {
  const dataMap = new Map<String, any>();
  let mainInboundAudioData: any;
  let mainInboundVideoData: any;
  let connectedIceCandidatePair: any;

  stats.forEach(function (res) {
    dataMap.set(res["id"], res);

    if (res.type === "candidate-pair" && res.state === "succeeded") {
      connectedIceCandidatePair = res;
    } else if (res.mediaType === "audio" && res.type === "inbound-rtp" && res.id.indexOf("rtcp") < 0) {
      mainInboundAudioData = res;
    } else if (res.mediaType === "video" && res.type === "inbound-rtp" && res.id.indexOf("rtcp") < 0) {
      mainInboundVideoData = res;
    }
  });

  let timestamp_str: string | undefined = undefined;
  const metricTick: WebRTCMetric = {};
  if (connectedIceCandidatePair) {
    timestamp_str = connectedIceCandidatePair["timestamp"];
    const local = dataMap.get(connectedIceCandidatePair["localCandidateId"]);
    const remote = dataMap.get(connectedIceCandidatePair["remoteCandidateId"]);

    metricTick.transport = dataMap.get(connectedIceCandidatePair["transportId"]);
    scrubMap(local, ["id", "type", "timestamp", "transportId"]);
    scrubMap(remote, ["id", "type", "timestamp", "transportId"]);
    scrubMap(connectedIceCandidatePair, ["id", "type", "timestamp", "localCandidateId", "remoteCandidateId", "transportId", "state"]);

    metricTick.connectedIceCandidate = {
      connection: connectedIceCandidatePair,
      local: local,
      remote: remote,
    };
  }

  if (mainInboundAudioData) {
    timestamp_str = mainInboundAudioData["timestamp"];
    const track = dataMap.get(mainInboundAudioData["trackId"]);

    metricTick.transport = dataMap.get(mainInboundAudioData["transportId"]) || null;
    scrubMap(track, ["id", "type", "timestamp", "trackIdentifier", "kind"]);
    scrubMap(mainInboundAudioData, ["id", "type", "timestamp", "transportId", "kind", "trackId", "mediaType", "transportId", "codecId"]);

    metricTick.audio = {
      stream: mainInboundAudioData,
      track: track || null,
    };
  }

  if (mainInboundVideoData) {
    timestamp_str = mainInboundVideoData["timestamp"];
    const track = dataMap.get(mainInboundVideoData["trackId"]);

    metricTick.transport = dataMap.get(mainInboundVideoData["transportId"]) || null;
    scrubMap(track, ["id", "type", "timestamp", "trackIdentifier", "kind"]);
    scrubMap(mainInboundVideoData, ["id", "type", "timestamp", "transportId", "kind", "trackId", "mediaType", "transportId", "codecId"]);

    metricTick.video = {
      stream: mainInboundVideoData,
      track: track || null,
    };
  }

  if (timestamp_str) {
    metricTick["@timestamp"] = timestamp_str;
  }

  if (metricTick.transport) {
    scrubMap(metricTick.transport, ["id", "timestamp", "type", "selectedCandidatePairId"]);
  }

  return metricTick;
}

// Helper function to remove keys from a map
function scrubMap(map: any, keyList: Array<string>) {
  // If no map, no keys to remove
  if (!map) {
    return;
  }
  for (const key of keyList) {
    delete map[key];
  }
}
