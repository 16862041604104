import React, { FC, PropsWithChildren, useContext, useEffect } from "react";

import { NetworkState, NetworkStateContext } from "../../../context/NetworkStateContext";
import { DisplayableError, createLogger } from "../../../common/utils";
import { ErrorDisplay } from "./ErrorDisplay";

export const NetworkChecker: FC<PropsWithChildren<{}>> = ({ children }) => {
  const logger = createLogger("[ChannelContainer]");
  const { networkState } = useContext(NetworkStateContext);

  useEffect(() => {
    if (networkState === NetworkState.OFFLINE) {
      logger.warn("network state offline");
    }
  }, [networkState]);

  return (<>{
    networkState === NetworkState.OFFLINE ?
      <ErrorDisplay error={new DisplayableError("NETWORK_OFFLINE", "waiting for network to come back...")} /> :
      children
  }</>);
};