import React, { useEffect, useReducer, useRef } from "react";
import videojs, { VideoJsPlayer } from "video.js";
import "video.js/dist/video-js.css";
import "../videojs/video.css";
import "../videojs/MediaStreamVJSTech";
import { VideoPlayerState } from "../models";

type Props = {
  shouldMute?: boolean;
  options?: videojs.PlayerOptions;
  onReady?: (player: VideoJsPlayer) => void;
};

type State = {
  status: VideoPlayerState
};

type Action =
  | { type: "player/initializing" }
  | { type: "player/ready" };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "player/initializing":
      return {
        ...state,
        status: VideoPlayerState.INITIALIZING,
      };
    case "player/ready":
      return {
        ...state,
        status: VideoPlayerState.READY,
      };
    default:
      return state;
  }
};

const DEFAULT_PLAYER_OPTIONS = {
  bigPlayButton: false,
  techOrder: ['MediaStreamTech'],
  controls: true,
  userActions: {
    doubleClick: true,
  },
  responsive: false,
  aspectRatio: "16:9",
}

export const VideoPlayer: React.FC<Props> = ({ shouldMute, options, onReady }) => {
  const videoRef = useRef<HTMLElement>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);

  const [{ status }, dispatch] = useReducer(reducer, {
    status: VideoPlayerState.UNINITIALIZED,
  });

  useEffect(() => {
    // video should never be null... theoretically...
    if (videoRef.current === null) {
      return;
    }

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      dispatch({ type: "player/initializing" });
      const videoElement = document.createElement("video-js");
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const effectiveOptions = {
        ...DEFAULT_PLAYER_OPTIONS,
        muted: shouldMute,
        ...options,
      }

      const player = playerRef.current = videojs(videoElement, effectiveOptions);
      player.ready(() => {
        dispatch({ type: "player/ready" });
      });
    }

    return function cleanup() {
      if (playerRef.current) playerRef.current.dispose();
    };
  }, [videoRef, shouldMute, options]);

  const handleFullscreenToggle = () => {
    if (!playerRef.current) {
      return;
    }
    if (playerRef.current.isFullscreen()) {
      playerRef.current.exitFullscreen();
    } else {
      playerRef.current.requestFullscreen();
    }
  };

  const handleMuteToggle = () => {
    if (!playerRef.current) {
      return;
    }
    const mutedState = playerRef.current.muted();
    playerRef.current.muted(!mutedState);
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.target !== document.body && !(e.target as HTMLElement).classList.contains("video-js")) {
        return;
      }
      if (status === VideoPlayerState.READY) {
        if (e.key === "f") {
          handleFullscreenToggle();
        }
        if (e.key === "m") {
          handleMuteToggle();
        }
      }
    };

    if (playerRef.current === null || status !== VideoPlayerState.READY) {
      return;
    }

    if (onReady) onReady(playerRef.current);
    document.documentElement.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.documentElement.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [status]);

  return (
    <div data-vjs-player>
      <div ref={videoRef as React.RefObject<HTMLDivElement>} />
    </div>
  );
};
