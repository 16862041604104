import { Hook, Unhook } from "console-feed";
import { Message } from "console-feed/lib/definitions/Console";
import React, { FC, PropsWithChildren, createContext, useEffect, useState, useRef } from "react";

const VERSION = process.env.VERSION || "";

// @ts-ignore TODO: Fix typing
export interface CapturedLog extends Message {
    timestamp: Date;
}

type LogTrackingContextType = {
    ready: boolean,
    startTime: number;
    logs: CapturedLog[];
    submitLogs: (opaqueId: any, customSubject?: string) => Promise<boolean | undefined>;
};

export const LogTrackingContext = createContext<LogTrackingContextType>({
    ready: false,
    startTime: Date.now(),
    logs: [],
    submitLogs: async (opaqueId: any, customSubject?: string) => { return false },
});

function transformLog(log: CapturedLog) {
    return log.timestamp.toISOString() + ": " + log.data?.map((datum: any) => (typeof datum === "object" ? JSON.stringify(datum) : datum)).join(" ");
}

export const LogTrackingProvider: FC<PropsWithChildren> = ({ children }) => {
    const [ready, setReady] = useState<boolean>(false);
    const [startTime] = useState<number>(Date.now());
    const [logs, setLogs] = useState<CapturedLog[]>([]);
    const logsRef = useRef<CapturedLog[] | undefined>([]);

    useEffect(() => {
        const hookedConsole = Hook(
            window.console,
            (log) => {
                if (logsRef.current === undefined) return;

                const newEntry = {
                    ...log,
                    timestamp: new Date(),
                };
                logsRef.current.push(newEntry);
                setLogs(currLogs => [...currLogs, newEntry]);
            },
            false,
        );

        setReady(true);
        return () => {
            Unhook(hookedConsole);
        }
    }, []);

    async function submitLogs(opaqueId: any, customSubject?: string) {
        if (!logsRef.current) return;

        const blob = new Blob([logsRef.current.map(transformLog).join("\n")], { type: "application/json" });
        const formData = new FormData();
        formData.append("subject", customSubject || `STREAMSIX Logs`);
        formData.append("body", `STREAMSIX\r\n\r\nVersion: ${VERSION}\r\nopaqueId: ${opaqueId}\r\nTime of Log: ${new Date().toString()}`);
        formData.append("attachments[console.log]", blob);
        const resp = await fetch(process.env.REACT_APP_APPLOGS_ENDPOINT || "https://portal.timeplay.com/api/v2/applogs", {
            method: "POST",
            body: formData,
        });
        if (resp.status !== 200) {
            console.error(`Log submission returned with status code ${resp.status}`);
            return false;
        }
        return true;
    };

    return (
        <LogTrackingContext.Provider value={{ ready, startTime, logs, submitLogs }}>
            {children}
        </LogTrackingContext.Provider>
    );
};
