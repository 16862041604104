import React, { FC, PropsWithChildren, createContext, useEffect, useState, useContext, useRef } from "react";
import { createLogger, RefreshableError } from "../../../common/utils";
import { Timeout } from "../../../common/types";
import { v4 as uuidv4 } from "uuid";
import { useErrorBoundary } from "react-error-boundary";
import { ChannelContext } from "./ChannelContext";
import Janus, { JanusJS } from "../janus";
import { createWebRTCMetric } from "../metrics";
import { LogTrackingContext } from "../../../context/LogTrackingContext";
import DetectRTC from "detectrtc";

///////////////////////////////////////////////////////////////////////////////
// Definitions
///////////////////////////////////////////////////////////////////////////////
const PROXY_URL = process.env.REACT_APP_PROXY_URL || "https://proxy.timeplay.tv";
const JANUS_PORT = process.env.REACT_APP_JANUS_PORT || "60049";
const WEBRTC_METRIC_INTERVAL = 5000;
const ICE_DISCONNECT_TIMEOUT = 6000;

const ICE_SERVERS = [
  {
    urls: ["stun:3.230.211.77:80"],
  },
  {
    urls: "turn:3.230.211.77:80",
    username: "turn1",
    credential: "jB7YVuQwup6ccL",
  }
];

export enum JanusStreamState {
  IDLE,
  SESSION_CREATE,
  PLUGIN_ATTACH,
  READY,
  READY_NO_TRACKS,
}

///////////////////////////////////////////////////////////////////////////////
// Context
///////////////////////////////////////////////////////////////////////////////
type Props = {clientId: string} & PropsWithChildren;

type JanusContextType = {
  janusStreamState: JanusStreamState;
  janusIsInitialized: boolean;
  janusReconnectAttempts: number;
  iceState: string | undefined;
  mediaStream: MediaStream;

  startJanusStream: () => void;
  restartJanusStream: () => void;
};

export const JanusContext = createContext<JanusContextType>({
  janusStreamState: JanusStreamState.IDLE,
  janusIsInitialized: false,
  janusReconnectAttempts: 0,
  iceState: undefined,
  mediaStream: new MediaStream(),

  startJanusStream: () => { },
  restartJanusStream: () => { },
});

export const JanusContextProvider: FC<Props> = ({ children, clientId }) => {
  const logger = createLogger("[JanusContext]");
  const { showBoundary } = useErrorBoundary();

  // if startJanusStream is called multiple times, then startIdRef is used to
  // set only the latest startJanusStream call results, also used as a
  // "isMounted" check
  const startIdRef = useRef<string | undefined>(undefined);
  const mediaStreamRef = useRef<MediaStream>(new MediaStream());

  const [isJanusInitialized, setIsJanusInitialized] = useState<boolean>(false);
  const [janusStreamState, setJanusStreamState] = useState<JanusStreamState>(JanusStreamState.IDLE);
  const [janusIsInitialized, setJanusIsInitialized] = useState<boolean>(false);
  const [janusReconnectAttempts, setJanusReconnectAttempts] = useState<number>(0);
  const [iceState, setIceState] = useState<string | undefined>(undefined);
  const [mediaStream, setMediaStream] = useState<MediaStream>(new MediaStream());

  const janusRef = useRef<Janus | undefined>(undefined);
  const pluginHandleRef = useRef<JanusJS.PluginHandle | undefined>(undefined);
  const webRTCMetricTimerRef = useRef<Timeout | undefined>(undefined);
  const iceDisconnectTimer = useRef<Timeout | undefined>(undefined);

  const { selectedNode } = useContext(ChannelContext);
  const { startTime } = useContext(LogTrackingContext);

  const reset = (startId: string | undefined) => {
    if (startId === undefined) return;
    logger.log(`reseting state info for ${startId}`);
    if (startId !== startIdRef.current) {
      logger.warn(`attempting to reset with a startId that is not equal to current, skipping`);
      return;
    };

    logger.info("resetting states...");
    if (webRTCMetricTimerRef.current) {
      logger.info("clearing webRTCMetricTimer");
      clearInterval(webRTCMetricTimerRef.current)
    };
    webRTCMetricTimerRef.current = undefined;

    if (iceDisconnectTimer.current) {
      logger.info("clearing iceDisconnectTimer");
      clearInterval(iceDisconnectTimer.current);
    }

    if (pluginHandleRef) {
      logger.info("detaching pluginHandle");
      pluginHandleRef.current?.detach()
    };
    pluginHandleRef.current = undefined;

    if (janusRef) {
      logger.info("destroying janus session");
      janusRef.current?.destroy({})
    };
    janusRef.current = undefined;

    setJanusStreamState(JanusStreamState.IDLE);
    setJanusIsInitialized(false);
    setJanusReconnectAttempts(0);
    setIceState(undefined);

    startIdRef.current = undefined;
    mediaStreamRef.current = new MediaStream();
  }

  // Exported functions
  ///////////////////////////////////////////////////////////////////////////////
  const startJanusStream = () => {
    reset(startIdRef.current);

    const newStartId = uuidv4();
    startIdRef.current = newStartId;
    setJanusStreamState(JanusStreamState.SESSION_CREATE)
    logger.info(`new janus stream started (ref=${newStartId})`);
  }

  const restartJanusStream = () => {
    startJanusStream();
  }

  // State management
  ///////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    Janus.init({
      debug: true,
      callback: () => {
        logger.log("Janus library initialized");
        setIsJanusInitialized(true);
      },
    });

    return () => {
      logger.debug("unmounting!");
      reset(startIdRef.current);
    }
  }, []);

  useEffect(() => {
    if (janusStreamState !== JanusStreamState.SESSION_CREATE) return;
    if (!selectedNode) return;

    const closureStartId = startIdRef.current;
    const closureJanusInstance = new Janus({
      server: `${PROXY_URL}/${selectedNode.host}/${JANUS_PORT}/janus`,
      iceServers: ICE_SERVERS,
      success: function () {
        if (closureStartId !== startIdRef.current) {
          closureJanusInstance.destroy({});
          return;
        };
        logger.log("Janus session was created");
        setJanusStreamState(JanusStreamState.PLUGIN_ATTACH);
      },
      error: function (error: any) {
        if (closureStartId !== startIdRef.current) {
          closureJanusInstance.destroy({});
          return;
        };
        logger.error("Janus error: " + error);
        showBoundary(new RefreshableError("STREAM_INIT_FAILED", `stream initialization error: ${error}`));
      },
      destroyed: function () {},
    });

    janusRef.current = closureJanusInstance;
  }, [selectedNode, janusStreamState, isJanusInitialized]);

  useEffect(() => {
    if (janusStreamState !== JanusStreamState.PLUGIN_ATTACH) return;
    const closureStartId = startIdRef.current;
    const closureJanus = janusRef.current;

    if (selectedNode && closureJanus && janusStreamState === JanusStreamState.PLUGIN_ATTACH) {
      const mountpointID = selectedNode.mountpointID;

      let closurePluginHandle: JanusJS.PluginHandle | undefined = undefined;
      function closureCleanup() {
        if (closurePluginHandle) closurePluginHandle.detach();
        if (closureJanus) closureJanus.destroy({});
      }

      closureJanus.attach({
        plugin: "janus.plugin.streaming",
        opaqueId: clientId,
        success: function (pluginHandle: JanusJS.PluginHandle) {
          closurePluginHandle = pluginHandle;
          if (closureStartId !== startIdRef.current) {
            closureCleanup();
            return;
          };
          pluginHandleRef.current = pluginHandle;
          const streaming = pluginHandleRef.current;
          logger.log("Plugin attached! (" + streaming.getPlugin() + ", id=" + streaming.getId() + ")");

          // Setup streaming session
          const body = { request: "watch", id: mountpointID };
          streaming.send({ message: body });

          // Setup an interval timer to gather metrics from the tracks
          let config = pluginHandleRef.current?.webrtcStuff;
          webRTCMetricTimerRef.current = setInterval(function () {
            if (!config?.pc || !config?.pc.getStats) {
              closureCleanup();
              return;
            };

            let peerConnection: RTCPeerConnection = config.pc;
            peerConnection.getStats().then(function (stats) {
              let metricTick = createWebRTCMetric(stats);

              if (metricTick.audio || metricTick.video || metricTick.connectedIceCandidate) {
                logger.debug({
                  session_id: janusRef.current?.getSessionId(),
                  handle_id: pluginHandleRef.current?.getId(),
                  event: "WEBRTC_METRICS",
                  metric: metricTick,
                  timeElapsedMS: Date.now() - startTime,
                });
              }
            });
          }, WEBRTC_METRIC_INTERVAL);
        },
        error: function (error: any) {
          if (closureStartId !== startIdRef.current) {
            closureCleanup();
            return;
          };
          logger.error("  -- Error attaching plugin... ", error);
          throw new RefreshableError("STREAM_PLUGIN_ATTACH_FAILED", `failed to attach to streaming plugin`);
        },
        iceState: function (state: any) {
          if (closureStartId !== startIdRef.current) {
            closureCleanup();
            return;
          };
          logger.log("ICE state changed to " + state);
          setIceState(state);
        },
        webrtcState: function (on: any) {
          if (closureStartId !== startIdRef.current) {
            closureCleanup();
            return;
          };
          logger.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
        },
        onmessage: function (msg: any, jsep: any) {
          if (closureStartId !== startIdRef.current) {
            closureCleanup();
            return;
          };
          logger.debug(" ::: Got a message :::", msg);

          const err = msg.error;
          if (err) {
            logger.error(`received error from streaming plugin: ${err}`);
            // stop the stream, maybe restart?
            throw new RefreshableError("STREAMING_SERVER_ERROR", "server side error occurred");
          }

          if (msg.result && msg.result.status) {
            const status = msg.result.status;
            if (status === "starting") {
              logger.log("Starting, please wait...");
            }
            else if (status === "started") {
              logger.info("Started");
            }
            else if (status === "stopped") {
              pluginHandleRef.current?.send({ message: { request: "stop" } });
              pluginHandleRef.current?.hangup();
              logger.info("stopped");
            };
          }

          if (jsep) {
            logger.debug("Handling SDP as well...", jsep);
            const stereo = jsep.sdp.indexOf("stereo=1") !== -1;
            // Offer from the plugin, let's answer
            pluginHandleRef.current?.createAnswer({
              jsep: jsep,
              // We only specify data channels here, as this way in case they
              // were offered we'll enable them. Since we don't mention audio
              // or video tracks, we autoaccept them as recvonly (since we
              // won't capture anything ourselves)
              tracks: [
                { type: 'data' },
              ],
              customizeSdp: function (jsep: any) {
                if (stereo && jsep.sdp.indexOf("stereo=1") === -1) {
                  // Make sure that our offer contains stereo too
                  jsep.sdp = jsep.sdp.replace("useinbandfec=1", "useinbandfec=1;stereo=1");
                }
              },
              success: function (jsep: any) {
                logger.debug("Got SDP!", jsep);
                const body = { request: "start" };
                pluginHandleRef.current?.send({ message: body, jsep: jsep });
              },
              error: function (error: any) {
                logger.error("WebRTC error:", error);
                showBoundary(new RefreshableError("SDP_CREATE_ANSWER_FAILED", `failed to send sdp to server`));
              },
            });
          }
        },
        onremotetrack: function (track: MediaStreamTrack, mid: string, on: boolean) {
          if (closureStartId !== startIdRef.current) {
            closureCleanup();
            return;
          };

          logger.debug(" ::: Got a remote track ::: ", JSON.stringify({
            contentHint: track.contentHint,
            enabled: track.enabled,
            mid: mid,
            id: track.id,
            kind: track.kind,
            label: track.label,
            muted: track.muted,
            readyState: track.readyState,
            on: on,
          }));

          // add track if it is "on", otherwise remove it
          (on ? mediaStreamRef.current.addTrack(track) : mediaStreamRef.current.removeTrack(track));

          if (mediaStreamRef.current.getTracks().length < 2) {
            setMediaStream(mediaStreamRef.current);
            setJanusStreamState(JanusStreamState.READY_NO_TRACKS);
          } else {
            setMediaStream(mediaStreamRef.current);
            setJanusStreamState(JanusStreamState.READY);
          }
        },
        ondataopen: function (data: any) {
          if (closureStartId !== startIdRef.current) {
            closureCleanup();
            return;
          };
          logger.log("The DataChannel is available!");
        },
        ondata: function (data: any) {
          if (closureStartId !== startIdRef.current) {
            closureCleanup();
            return;
          };
          logger.debug("We got data from the DataChannel!", data);
        },
        slowLink: function (uplink, lost, mid) {
          if (closureStartId !== startIdRef.current) {
            closureCleanup();
            return;
          };
          logger.warn(`Janus reports problems ${uplink ? "sending" : "receiving"} packets on mid ${mid} (${lost} lost packets)`);
        },
        oncleanup: function () {
          if (closureStartId !== startIdRef.current) return;
          logger.log(" ::: Got a cleanup notification :::");
        },
      });
    }
  }, [selectedNode, janusStreamState, clientId]);

  useEffect(() => {
    if (iceState === "disconnected") {
      iceDisconnectTimer.current = setTimeout(() => {
        showBoundary(new RefreshableError("ICE_DISCONNECTED", `Client Id: ${clientId}`));
      }, ICE_DISCONNECT_TIMEOUT)
    } else if (iceState === "connected") {
      if (iceDisconnectTimer.current) clearTimeout(iceDisconnectTimer.current);
    }

    // Chrome never seems to go into a failed state when it can't re-establish ICE.
    // Instead, it goes into a disconnected state right away. Firefox will keep re-trying ICE
    // until it goes into the failed state at which point we will take over.
    if (iceState === "failed" || (iceState === "disconnected" && DetectRTC.browser.isChrome)) {
      const janus = janusRef.current;

      // If there is no instance of Janus, we do not need to handle the ICE reconnect.
      if (!janus) {
        return;
      }

      logger.log("ICE failed. Attempting to reconnect...");
      janus.reconnect({
        success: () => {
          logger.log("Successfully reconnected to Janus!");
          setJanusStreamState(JanusStreamState.PLUGIN_ATTACH);
        },
        error: (error: any) => {
          logger.log("reconnect error: " + error);

          janusRef.current?.destroy({});
          setJanusStreamState(JanusStreamState.SESSION_CREATE);
        },
      });
    }
  }, [iceState]);

  // JSX
  ///////////////////////////////////////////////////////////////////////////////
  return (
    <JanusContext.Provider value={{
      // states
      janusStreamState,
      janusIsInitialized,
      janusReconnectAttempts,
      mediaStream,
      iceState,

      // functions
      startJanusStream,
      restartJanusStream,
    }}>
      {children}
    </JanusContext.Provider>
  );
};
