/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getChannel = /* GraphQL */ `
  query GetChannel($name: String!) {
    getChannel(name: $name) {
      name
      players
      capacity
      sessionId
      edgeNodes {
        items {
          id
          host
          mountpointID
          showID
          channelID
          channel {
            name
            players
            capacity
            sessionId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $name: String
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChannels(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        players
        capacity
        sessionId
        edgeNodes {
          items {
            id
            host
            mountpointID
            showID
            channelID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEdgeNode = /* GraphQL */ `
  query GetEdgeNode($id: ID!) {
    getEdgeNode(id: $id) {
      id
      host
      mountpointID
      showID
      channelID
      channel {
        name
        players
        capacity
        sessionId
        edgeNodes {
          items {
            id
            host
            mountpointID
            showID
            channelID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEdgeNodes = /* GraphQL */ `
  query ListEdgeNodes(
    $filter: ModelEdgeNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEdgeNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        host
        mountpointID
        showID
        channelID
        channel {
          name
          players
          capacity
          sessionId
          edgeNodes {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      createdAt
      updatedAt
      channelID
      channel {
        name
        players
        capacity
        sessionId
        edgeNodes {
          items {
            id
            host
            mountpointID
            showID
            channelID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chatChannel
      author
      content
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        channelID
        channel {
          name
          players
          capacity
          sessionId
          edgeNodes {
            nextToken
          }
          createdAt
          updatedAt
        }
        chatChannel
        author
        content
      }
      nextToken
    }
  }
`;
export const getChatMessage = /* GraphQL */ `
  query GetChatMessage($id: ID!) {
    getChatMessage(id: $id) {
      id
      timestamp
      channel
      author
      message
    }
  }
`;
export const listChatMessages = /* GraphQL */ `
  query ListChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        channel
        author
        message
      }
      nextToken
    }
  }
`;
export const getFeature = /* GraphQL */ `
  query GetFeature($name: String!) {
    getFeature(name: $name) {
      name
      enabled
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $name: String
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeatures(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        enabled
      }
      nextToken
    }
  }
`;
export const getInfo = /* GraphQL */ `
  query GetInfo($name: String!) {
    getInfo(name: $name) {
      name
      value
    }
  }
`;
export const listInfos = /* GraphQL */ `
  query ListInfos(
    $name: String
    $filter: ModelInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInfos(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        value
      }
      nextToken
    }
  }
`;
export const chatMessagesByChannelTimestamp = /* GraphQL */ `
  query ChatMessagesByChannelTimestamp(
    $channel: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatMessagesByChannelTimestamp(
      channel: $channel
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        channel
        author
        message
      }
      nextToken
    }
  }
`;
