import * as DetectRTC from "detectrtc";
import React, { useContext, useEffect, useState } from "react";

import { Dimmer, Header, Icon, Loader } from "semantic-ui-react";

import Janus from "../janus";
import { VideoPlayerState } from "../models";
import { createLogger, DisplayableError, RefreshableError } from "../../../common/utils";
import { VideoPlayer } from "./VideoPlayer";
import { VideoJsPlayer } from "video.js";
import { useErrorBoundary } from "react-error-boundary";
import { ChannelContext, ChannelState } from "../context/ChannelContext";
import { JanusContext, JanusStreamState } from "../context/JanusContext";
import { Timeout } from "../../../common/types";

const VERSION = process.env.VERSION || "";
const LOADING_TIMEOUT = 35 * 1000;

const enum UIState {
  IDLE,
  LOADING,
  PLAYING,
}

type Props = {
  clientId: string;
};

export const Streaming: React.FC<Props> = ({ clientId }) => {
  const logger = createLogger("[Streaming]");
  const { showBoundary } = useErrorBoundary();
  const {
    channelState,
    channelName,
    startChannelDataFetch,
    restartChannelDataFetch,
  } = useContext(ChannelContext);

  const {
    startJanusStream,
    mediaStream,
    janusStreamState,
  } = useContext(JanusContext);

  const playerRef = React.useRef<VideoJsPlayer | null>(null);
  const urlParams = new URLSearchParams(window.location.search);

  // if set to "1", will skip browser/os checks, use this in place of the
  // modal bypass feature we used to have for unsupported browsers
  const forcePlay = urlParams.get("forceplay") === "1";

  // if set to "1", will attempt to automatically plays the stream when the
  // page is loaded, may not always work due to browser policies
  const shouldAutoplay = urlParams.get("autoplay") === "1";

  // if set to "1", will attempt to full-screen the player at the first chance
  // it gets
  const shouldFullscreen = urlParams.get("fullscreen") === "1";

  // if set to "1", will start the video muted.  Note that if autoplay is set,
  // then mute will automatically be set, autoplay doesn't work without it
  const shouldMute = shouldAutoplay ? true : urlParams.get("mute") === "1";


  // the url to an image to use as a placeholder when there is no shows running
  const placeholderImage = urlParams.get("placeholderImage") || "";
  const fetchChannelIntervalStr = urlParams.get("fetchChannelInterval")
  const fetchChannelInterval = fetchChannelIntervalStr ? 1000 * parseInt(fetchChannelIntervalStr) : 1000 * 60;

  // const [streamState, setPlayAttempts] = useState<number>(0);
  const [uiState, setUIState] = useState<UIState>(UIState.IDLE);
  const [playAttempts, setPlayAttempts] = useState<number>(0);
  const [videoPlayerState, setVideoPlayerState] = useState<VideoPlayerState>(VideoPlayerState.UNINITIALIZED);
  const [loadingTimeout, setLoadingTimeout] = useState<Timeout | undefined>(undefined);

  // Callback functions
  /////////////////////////////////////////////////////////////////////////////
  const handleVideoPlayerReady = (player: VideoJsPlayer) => {
    player.on('dispose', () => {
      playerRef.current = null;
      setVideoPlayerState(VideoPlayerState.INITIALIZING);
    });

    playerRef.current = player;
    setVideoPlayerState(VideoPlayerState.READY);
  };

  const handlePlay = () => {
    startChannelDataFetch();
    startJanusStream();

    setUIState(UIState.LOADING);
    // Hack for getting Safari not to mark this video as not being autoplay.
    if (DetectRTC.browser.isSafari) {
      playerRef.current?.load();
    }

    setLoadingTimeout(
      setTimeout(() => {
        showBoundary(new RefreshableError("CONNECTION_TIMEOUT", "failed to establish the stream before timeout"));
      }, LOADING_TIMEOUT)
    );
  };

  // State management
  /////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (channelState === ChannelState.CHANNEL_EMPTY) {
      if (placeholderImage) {
        if (loadingTimeout) { clearInterval(loadingTimeout); }

        setTimeout(() => {
          restartChannelDataFetch();
        }, fetchChannelInterval);
      } else {
        throw new RefreshableError("CHANNEL_EMPTY", "no show is currently playing on this channel", false);
      }
    }
  }, [channelState, placeholderImage]);

  useEffect(() => {
    logger.debug({ DetectRTC });

    if (!forcePlay) {
      if (!DetectRTC.browser.isChrome && !DetectRTC.browser.isFirefox && !DetectRTC.browser.isSafari) {
        showBoundary(new DisplayableError("Unsupported Browser", `Your browser is not currently supported`));
      }

      if (!Janus.isWebrtcSupported()) {
        showBoundary(new DisplayableError(undefined, `WebRTC is not supported or is disabled by your browser.`));
      }
    }

    const queryParameters = [];
    for (let [k, v] of urlParams.entries()) {
      queryParameters.push(`${k}=${v}`);
    }

    logger.log("=========== Client Information ===========");
    logger.log(`clientId: ${clientId}`);
    logger.log(`path: ${window.location.pathname}`);
    logger.log(`queryParameters: ?${queryParameters.join("&")}`);
    logger.log(`version: ${VERSION}`);
    logger.log(`browser: ${DetectRTC.browser.name}`);
    logger.log(`os: ${DetectRTC.osName} ${DetectRTC.osVersion}`);
    logger.log(`channelName: ${channelName}`);
    logger.log("========== Channel Configuration =========");
    logger.log(`forcePlay: ${forcePlay}`);
    logger.log(`shouldAutoplay: ${shouldAutoplay}`);
    logger.log(`shouldFullscreen: ${shouldFullscreen}`);
    logger.log(`shouldMute: ${shouldMute}`);
    logger.log("========= End Client Information =========");
  }, [channelName, clientId, forcePlay, shouldAutoplay, shouldFullscreen, shouldMute]);

  // autoplay
  useEffect(() => {
    if (videoPlayerState === VideoPlayerState.READY && shouldAutoplay) {
      handlePlay();
    }
  }, [videoPlayerState, shouldAutoplay]);

  useEffect(() => {
    if (
      uiState === UIState.LOADING &&
      playerRef.current &&
      videoPlayerState === VideoPlayerState.READY &&
      janusStreamState === JanusStreamState.READY
    ) {
      if (loadingTimeout) { clearInterval(loadingTimeout); }

      const srcStream = {
        type: 'application/webrtc-peer',
        src: 'hack', // this can be anything , but cannot be empty
        stream: mediaStream,
      }

      logger.info(`setting new mediastream with id: ${mediaStream.id}`);
      playerRef.current.loadMedia({
        src: srcStream
      }, () => {
        setPlayAttempts(1);
        if (shouldFullscreen) {
          try {
            playerRef.current?.requestFullscreen();
          } catch (err) {
            logger.error(err);
          }
        }
      });
      setUIState(UIState.PLAYING);
    } else if (
      uiState === UIState.PLAYING &&
      playerRef.current &&
      videoPlayerState === VideoPlayerState.READY &&
      janusStreamState === JanusStreamState.READY_NO_TRACKS
    ) {
      logger.info("no tracks available in media stream, transitioning back to loading state");
      playerRef.current.reset();
      setUIState(UIState.LOADING);

      // If a placeholder image is set, we want to immediately reload so we
      // don't show the "end of stream reached" message
      if (placeholderImage) {
        window.location.reload();
      } else {
        setLoadingTimeout(
          setTimeout(() => {
            showBoundary(new RefreshableError(undefined, "end of stream reached", false, true));
          }, LOADING_TIMEOUT)
        );
      }
    }

    logger.info(`detected state change: ${uiState}, ${mediaStream.id}`);
    return () => {
      if (loadingTimeout) clearInterval(loadingTimeout);
    }
  }, [shouldFullscreen, uiState, videoPlayerState, janusStreamState, mediaStream]);

  useEffect(() => {
    if (uiState !== UIState.PLAYING) { return };

    const player = playerRef.current;
    if (playAttempts === 0) {
      return;
    }
    if (playAttempts > 5) {
      showBoundary(new RefreshableError("VIDEO_PLAY_FAILED", "Video could not be played. Please try again later."));
    }

    let playPromise = player?.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          setPlayAttempts(0);
          // This is needed for stream to recover in Chrome
          if (player) {
            (player.el as any).onloadedmetadata = () => {
              setPlayAttempts(playAttempts + 1);
            };
          }
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            logger.log("Stream played before it could load fully. Reloading video source and trying again...");
            player?.load();
            setPlayAttempts(playAttempts + 1);
          } else if (player && !player.muted) {
            player.muted(true);
            setPlayAttempts(playAttempts + 1);
          } else {
            logger.log("Problem playing video", err);
          }
        });
    }
  }, [uiState, playAttempts]);

  return (
    <>
      <Dimmer.Dimmable>
        <Dimmer onClick={handlePlay} active={videoPlayerState === VideoPlayerState.READY && uiState === UIState.IDLE}>
            <Header
              as="h2"
              icon
              inverted
              style={{
                textShadow: "1px 1px 2px black",
              }}
            >
              <Icon name="video play" />
              Click here to play!
            </Header>
        </Dimmer>

        {
          placeholderImage &&
          (
            // This prevents the placeholder image from showing up mid-show if
            // there are connection issues, instead it'll show the loader
            channelState === ChannelState.CHANNEL_EMPTY ||
            uiState !== UIState.PLAYING
          ) &&
            // TODO: setup proper zIndex-ing or make do without it by layering things correctly
            <img src={placeholderImage} style={{ height: "100vh", width: "100vw", position: "absolute", zIndex: "9999" }} />
        }

        <Loader active={
          uiState !== UIState.IDLE &&
          (
            uiState !== UIState.PLAYING ||
            videoPlayerState !== VideoPlayerState.READY ||
            janusStreamState !== JanusStreamState.READY ||
            channelState !== ChannelState.READY
          )
        } />
        <VideoPlayer shouldMute={shouldMute} onReady={handleVideoPlayerReady} />
      </Dimmer.Dimmable>
    </>
  );
};
