// Ripped from: https://gist.github.com/Exlord/c0029012278674865eb026d2e5b60f32
import videojs from 'video.js';

const Html5 = videojs.getTech('Html5');
const supportsSrcObject = 'srcObject' in HTMLMediaElement.prototype;

export default class MediaStreamTech extends Html5 {
    /**
     * A getter/setter for the `Html5` Tech's source object.
     * > Note: Please use {@link Html5#setSource}
     *
     * @param {Tech~SourceObject} [src]
     *        The source object you want to set on the `HTML5` techs element.
     *
     * @return {Tech~SourceObject|undefined}
     *         - The current source object when a source is not passed in.
     *         - undefined when setting
     *
     * @deprecated Since version 5.
     */
    src(src) {
        if (src === undefined) {
            return this.el_.srcObject;
        }

        // Setting src through `src` instead of `setSrc` will be deprecated
        this.setSrc(src);
    }

    setSource(source) {
        // mediastream is set on the stream property not src property
        if (supportsSrcObject) {
            this.el_.srcObject = source.stream;
        } else {
            this.el_.src = window.URL.createObjectURL(source.stream);
        }
        this.el_.play();
        this.trigger('playing');
    }

    /**
     * Check if HTML5 media is supported by this browser/device.
     *
     * @return {boolean}
     *         - True if HTML5 media is supported.
     *         - False if HTML5 media is not supported.
     */
    static isSupported() {
        return true;
    }

    /**
     * Check if the tech can support the given type
     *
     * @param {string} type
     *        The mimetype to check
     * @return {string} 'probably', 'maybe', or '' (empty string)
     */
    static canPlayType(type) {
        return type === 'application/webrtc-peer' ? 'probably' : '';
    }

    /**
     * Check if the tech can support the given source
     * @return {string} 'probably', 'maybe', or '' (empty string)
     */
    static canPlaySource(srcObj) {
        return MediaStreamTech.canPlayType(srcObj.type);
    }
}

videojs.registerTech('MediaStreamTech', MediaStreamTech);