import { useLocalStorage } from "./useLocalStorage";
import { v4 as uuidv4 } from "uuid";

export function useUUID() {
  const [uuid, setUUID] = useLocalStorage("timeplay.uuid", false);

  if (uuid) {
    return uuid;
  } else {
    const newUUID = uuidv4();
    setUUID(newUUID);

    return newUUID;
  }
}
