import React from "react";
import { Amplify } from "aws-amplify";
import awsconfig from "../aws-exports";

import { AppRouter } from "./AppRouter";
import { BrowserRouter } from "react-router-dom";
import { NetworkStateProvider } from "../context/NetworkStateContext";
import { LogTrackingProvider } from "../context/LogTrackingContext";

Amplify.configure(awsconfig);

const App: React.FC = () => {
  return (
    <LogTrackingProvider>
      <NetworkStateProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </NetworkStateProvider>
    </LogTrackingProvider>
  );
};

export default App;
