import React, { FC, PropsWithChildren, createContext, useEffect, useState } from "react";

// @ts-ignore TODO: Fix typing
export enum NetworkState {
    ONLINE,
    OFFLINE
}

type NetworkStateContextType = {
    networkState: NetworkState,
};

export const NetworkStateContext = createContext<NetworkStateContextType>({
    networkState: NetworkState.ONLINE,
});

export const NetworkStateProvider: FC<PropsWithChildren> = ({ children }) => {
    const [networkState, setNetworkState] = useState<NetworkState>(NetworkState.ONLINE);

    useEffect(() => {
        function setNetworkStateOnline() { setNetworkState(NetworkState.ONLINE) };
        function setNetworkStateOffline() { setNetworkState(NetworkState.OFFLINE) };

        window.addEventListener('online', setNetworkStateOnline);
        window.addEventListener('offline', setNetworkStateOffline);

        return () => {
            window.removeEventListener('online', setNetworkStateOnline);
            window.removeEventListener('offline', setNetworkStateOffline);
        }
    }, []);

    return (
        <NetworkStateContext.Provider value={{ networkState }}>
            {children}
        </NetworkStateContext.Provider>
    );
};
