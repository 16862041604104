export const DEFAULT_REFRESH_DELAY = 5000;

export enum Status {
  OFFLINE = "OFFLINE",
  OFFLINE_PENDING = "OFFLINE_PENDING",
  ONLINE = "ONLINE",
  ONLINE_PENDING = "ONLINE_PENDING",
  UNKNOWN = "UNKNOWN"
}

// RefreshableError wraps Error to make the page refresh after the error is
// thrown, if `shouldRefresh` is set to true.
//
// Includes some code to recover the prototype of the custom error type so
// that the custom error type is visible in logs, see:
//      https://stackoverflow.com/a/48342359
export class DisplayableError extends Error {
  public errorTitle: string | undefined;

  constructor(errorTitle: string | undefined, msg: string) {
    super(msg);
    Object.setPrototypeOf(this, new.target.prototype);

    this.errorTitle = errorTitle;
  }
}

// RefreshableError wraps Error to make the page refresh after the error is
// thrown, if `shouldRefresh` is set to true.
//
// Includes some code to recover the prototype of the custom error type so
// that the custom error type is visible in logs, see:
//      https://stackoverflow.com/a/48342359
export class RefreshableError extends DisplayableError {
  public shouldRefresh: boolean;
  public shouldSendLogs: boolean;
  public refreshDelay: number;

  constructor(errorTitle: string | undefined, msg: string, shouldSendLogs: boolean = true, shouldRefresh: boolean = true, refreshDelay = DEFAULT_REFRESH_DELAY) {
    super(errorTitle, msg);
    Object.setPrototypeOf(this, new.target.prototype);

    this.shouldSendLogs = shouldSendLogsResolver(shouldSendLogs);
    this.shouldRefresh = shouldRefreshResolver(shouldRefresh);
    this.refreshDelay = refreshDelay;
  }
}

export function shouldSendLogsResolver(defaultVal = true): boolean {
  const urlParams = new URLSearchParams(window.location.search);

  const shouldSendLogsOverride = urlParams.get("send_logs");
  if (shouldSendLogsOverride) {
    return shouldSendLogsOverride === "1";
  }

  return defaultVal
}

export function shouldRefreshResolver(defaultVal = true): boolean {
  const urlParams = new URLSearchParams(window.location.search);

  const shouldRefreshOverride = urlParams.get("auto_refresh");
  if (shouldRefreshOverride) {
    return shouldRefreshOverride === "1";
  }

  return defaultVal
}

export class UnknownStateError extends RefreshableError {
  constructor() {
    super("UNKNOWN_STATE", "unknown state reached", true, true);
  }
}

export function createLogger(prefix: string) {
  const logger: any = {};

  logger.trace = console.trace.bind(window.console, prefix);
  logger.info = console.info.bind(window.console, prefix);
  logger.log = console.log.bind(window.console, prefix);
  logger.warn = console.warn.bind(window.console, prefix);
  logger.error = console.error.bind(window.console, prefix);
  logger.debug = console.debug.bind(window.console, prefix);
  return logger;
}

export async function sleep(durationMilliseconds: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, durationMilliseconds));
}