import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const BASE_RETRY_MILLISECONDS = 100;
const MAX_SLEEP_DURATION_MILLISECONDS = 2000;

export const exponentialSleep = (iteration: number) => {
  const sleepDuration = Math.min(MAX_SLEEP_DURATION_MILLISECONDS, BASE_RETRY_MILLISECONDS ** iteration)
  return new Promise(resolve => setTimeout(resolve, sleepDuration));
};

export interface GraphQLOptions {
  input?: object;
  variables?: object;
  authMode?: GRAPHQL_AUTH_MODE;
};

export async function callGraphQL<T>(query: any, options?: any): Promise<GraphQLResult<T>> {
  return (await API.graphql(graphqlOperation(query, options))) as GraphQLResult<T>
};
