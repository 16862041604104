import React from "react";
import styled from "@emotion/styled";

import { FallbackProps } from "react-error-boundary";

import { FC, PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { Streaming } from "./Streaming";
import { ErrorDisplay } from "./ErrorDisplay";
import { useUUID } from "../../../hooks/useUUID";

import { ChannelProvider } from "../context/ChannelContext";
import { JanusContextProvider } from "../context/JanusContext";
import { NetworkChecker } from "./NetworkChecker";

type Params = {
  channel_name: string;
};

// Map for channel names that need to be translated for legacy reasons, would
// be better if we could remove this later by just going with a standard name
// for everything
const channelRenameMap = new Map([
  ["London South", "London"],
  ["London North", "LondonN"],
  ["Richmond Row", "Richmond"],
  ["Don Mills", "DonMills"],
]);

const VideoSection = styled.section`
  overflow: hidden auto;
  grid-area: content;
  height: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return <ErrorDisplay error={error} />
};

export const ChannelContainer: FC<PropsWithChildren<{}>> = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  let clientId = useUUID();
  if (urlParams.get("opaque_id")) {
    clientId = urlParams.get("opaque_id");
  }

  const { channel_name } = useParams<Params>();
  if (channel_name === undefined) {
    throw new Response("", {
      status: 404,
      statusText: "Missing channel name in the URL",
    });
  }

  return (
    <VideoSection>
      <NetworkChecker>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ChannelProvider channelName={channelRenameMap.get(channel_name) || channel_name}>
            <JanusContextProvider clientId={clientId}>
              <Streaming clientId={clientId} />
            </JanusContextProvider>
          </ChannelProvider>
        </ErrorBoundary>
      </NetworkChecker>
    </VideoSection>
  );
};
